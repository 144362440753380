import React from "react";
import { login, getMe } from "services/AuthService";

export default function useAuth() {
  const [me, setMe] = React.useState();

  const handleLogin = async (data) => {
    return await login(data);
  };

  const handleMe = React.useCallback(async () => {
    const { status, data } = await getMe();
    if (status === 200) {
      setMe(data);
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      handleMe();
    }
  }, [handleMe]);

  return { handleLogin, me };
}
