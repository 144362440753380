import api from "./api/api";

/*
  |--------------------------------------------------------------------------
  | login
  |--------------------------------------------------------------------------
  |
  |
  */
export const login = async (data) => {
  try {
    return await api.post("/auth/login", data);
  } catch (e) {
    return e;
  }
};

/*
  |--------------------------------------------------------------------------
  | getMe
  |--------------------------------------------------------------------------
  |
  |
  */
export const getMe = async () => {
  try {
    return await api.get("/me");
  } catch (e) {
    return e;
  }
};
