import React, { createContext, useState } from "react";
import { useAuth } from "hooks";

const getLocalUser = () => {
  const u = localStorage.getItem("user") || "";

  if (u) {
    return JSON.parse(u);
  }

  return {};
};

const AuthContext = createContext({});
const localUser = getLocalUser();

function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(localUser);
  const { me } = useAuth();

  const setAuthToken = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const setAuthUser = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const reloadUser = async () => {
    try {
      const { status, data } = await me();

      if (status === 200) {
        setAuthUser(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setAuthToken,
        setAuthUser,
        reloadUser,
        token,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
