import React from "react";
import { Snackbar, Slide, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const SnackbarContext = React.createContext({});

function SnackbarProvider({ children }) {
  const [alerts, setAlerts] = React.useState([]);

  const openSnackbar = (message, severity) => {
    const nAlerts = [...alerts];
    nAlerts.push({ message, severity });
    setAlerts(nAlerts);
  };

  const closeSnackbar = (index) => {
    const nAlerts = [...alerts];
    nAlerts.splice(index, 1);
    setAlerts(nAlerts);
  };

  const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
  };

  const content = (message, severity, index) => {
    if (severity === "success") {
      return (
        <Alert elevation={6} variant="filled" severity="success">
          {message}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(index)}
          >
            <Close />
          </IconButton>
        </Alert>
      );
    }

    if (severity === "error") {
      return (
        <Alert elevation={6} variant="filled" severity="error">
          {message}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(index)}
          >
            <Close />
          </IconButton>
        </Alert>
      );
    }

    if (severity === "info") {
      return (
        <Alert elevation={6} variant="filled" severity="info">
          {message}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(index)}
          >
            <Close />
          </IconButton>
        </Alert>
      );
    }

    if (severity === "warning") {
      return (
        <Alert elevation={6} variant="filled" severity="warning">
          {message}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(index)}
          >
            <Close />
          </IconButton>
        </Alert>
      );
    }
  };

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        closeSnackbar,
      }}
    >
      {children}

      {alerts &&
        alerts.map((item, index) => (
          <Snackbar
            key={index}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={true}
            onClose={() => closeSnackbar(index)}
            autoHideDuration={3000}
            TransitionComponent={SlideTransition}
          >
            {content(item.message, item.severity, index)}
          </Snackbar>
        ))}
    </SnackbarContext.Provider>
  );
}

export { SnackbarContext, SnackbarProvider };
