import { getFiles, downloadFile } from "services/FilesService";

export default function useFiles() {
  const handleFiles = async (folder) => {
    return await getFiles(folder);
  };

  const handleDownloadFile = async (file) => {
    return await downloadFile(file);
  };

  return { handleFiles, handleDownloadFile };
}
