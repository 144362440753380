import firebase from "firebase/app";
import "firebase/auth";
firebase.initializeApp({
  apiKey: "AIzaSyBc5mNdbrD0iUfKFgXYWd25UBEOnwdbKtY",
  authDomain: "inspecionar-8f9e0.firebaseapp.com",
  projectId: "inspecionar-8f9e0",
  storageBucket: "inspecionar-8f9e0.appspot.com",
  messagingSenderId: "568500093999",
  appId: "1:568500093999:web:b5b89e76fd84cfc0e772da",
});

export { firebase };
