import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { Warning, FolderOutlined } from "@material-ui/icons";

export default function FileList({ rows, onClick }) {
  const [totalItens, setTotalItens] = React.useState(0);
  const [totalSize, setTotalSize] = React.useState(0);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  React.useEffect(() => {
    let totalI = 0;
    let totalS = 0;

    rows.forEach((row) => {
      totalI++;
      totalS += row.size || 0;
    });

    setTotalItens(totalI);
    setTotalSize(formatBytes(totalS));
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      <Table size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="right">Quantidade</TableCell>
            <TableCell align="right">Tamanho</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            style={{ background: "#EEE" }}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            ></TableCell>
            <TableCell align="right">{totalItens} itens</TableCell>
            <TableCell align="right">{totalSize}</TableCell>
          </TableRow>
          {rows &&
            rows.map((row, index) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={index}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => onClick(row[".tag"], row.path_lower, row.name)}
                >
                  <Tooltip
                    title={row[".tag"] === "folder" ? "Acessar" : "Baixar"}
                  >
                    <>
                      {row[".tag"] === "folder" && <FolderOutlined />}
                      &nbsp;{row.name}
                    </>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  {row[".tag"] === "folder" && row.qty_files > 0
                    ? `${row.qty_files} itens`
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {row[".tag"] !== "folder" ? formatBytes(row.size || 0) : ""}
                </TableCell>
              </TableRow>
            ))}
          {!rows && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ alignItems: "center" }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Warning style={{ width: "20px" }} />
                &nbsp;&nbsp;Nenhum registro encontrado
              </TableCell>
            </TableRow>
          )}
          <TableRow
            style={{ background: "#EEE" }}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            ></TableCell>
            <TableCell align="right">{totalItens} itens</TableCell>
            <TableCell align="right">{totalSize}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
