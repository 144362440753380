import api from "./api/api";
const endpoint = "users";

/*
  |--------------------------------------------------------------------------
  | get
  |--------------------------------------------------------------------------
  |
  |
  */
export const get = async (page = 1) => {
  try {
    return await api.get(`${endpoint}?page=${page}`);
  } catch (e) {
    return e;
  }
};

/*
  |--------------------------------------------------------------------------
  | store
  |--------------------------------------------------------------------------
  |
  |
  */
export const store = async (data) => {
  try {
    if (data.id) {
      return await api.put(`${endpoint}/${data.id}`, data);
    } else {
      return await api.post(`${endpoint}`, data);
    }
  } catch (e) {
    return e;
  }
};

/*
  |--------------------------------------------------------------------------
  | remove
  |--------------------------------------------------------------------------
  |
  |
  */
export const remove = async (id) => {
  try {
    return await api.delete(`${endpoint}/${id}`);
  } catch (e) {
    return e;
  }
};
