import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import "index.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MuiConfig from "assets/muiconfig";
import { AuthProvider } from "./contexts/AuthContext";
import { SnackbarProvider } from "./contexts/SnackbarContext";

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={createMuiTheme(MuiConfig)}>
      <AuthProvider>
        <SnackbarProvider>
          <Routes />
        </SnackbarProvider>
      </AuthProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
