import api from "./api/api";

/*
  |--------------------------------------------------------------------------
  | getFiles
  |--------------------------------------------------------------------------
  |
  | getFiles user
  |
  */

export const getFiles = async (folder = "") => {
  try {
    return await api.get(`users/files?folder=${folder}`);
  } catch (e) {
    return e;
  }
};

/*
    |--------------------------------------------------------------------------
    | downloadFile
    |--------------------------------------------------------------------------
    |
    | downloadFile file
    |
    */

export const downloadFile = async (file) => {
  try {
    return await api.get(`users/download?file=${file}`);
  } catch (e) {
    return e;
  }
};
