export const getToken = () => localStorage.getItem("token");

export const isAuthenticated = () => !!localStorage.getItem("token");

export const login = (token) => {
  localStorage.setItem("token", token);
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

const checkTokenExist = () => {
  const authRoutes = ["/login"];

  if (!getToken() && !authRoutes.includes(window.location.pathname)) {
    logout();
  }
};

checkTokenExist();
