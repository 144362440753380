import React from "react";
import { get, store, remove } from "services/UserService";

export default function useUsers() {
  const handleUsers = React.useCallback(async (page) => {
    return await get(page);
  }, []);

  const handleUserStore = async (data) => {
    return await store(data);
  };

  const handleUserDelete = async (id) => {
    return await remove(id);
  };

  return { handleUserStore, handleUsers, handleUserDelete };
}
