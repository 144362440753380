import React from "react";
import { TextField, FormControl, Grid, Button } from "@material-ui/core";

export default function MoreFolders({
  add = false,
  data = { title: "", value: "" },
  onFocusOut = null,
  onRemove,
  onCreate,
}) {
  const [values, setValues] = React.useState(data);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <FormControl fullWidth margin="dense">
            <TextField
              name="title"
              label="Nome da pasta"
              variant="outlined"
              fullWidth
              size="small"
              value={values.title}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              onBlur={(e) => (onFocusOut ? onFocusOut(e) : {})}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth margin="dense">
            <TextField
              name="value"
              label="Caminho no dropbox"
              variant="outlined"
              fullWidth
              size="small"
              value={values.value}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              onBlur={(e) => (onFocusOut ? onFocusOut(e) : {})}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl margin="dense">
            {!add ? (
              <Button
                type="button"
                color="secondary"
                variant="contained"
                size="medium"
                onClick={() => onRemove()}
              >
                Remover
              </Button>
            ) : (
              <Button
                type="button"
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  onCreate(values);
                  setValues({ title: "", value: "" });
                }}
              >
                Adicionar
              </Button>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
