import api from "./api/api";
const endpoint = "file-log";

/*
  |--------------------------------------------------------------------------
  | get
  |--------------------------------------------------------------------------
  |
  |
  */
export const get = async (page = 1) => {
  try {
    return await api.get(`${endpoint}?page=${page}`);
  } catch (e) {
    return e;
  }
};

/*
  |--------------------------------------------------------------------------
  | download
  |--------------------------------------------------------------------------
  |
  |
  */
export const download = async () => {
  try {
    return await api.get(`${endpoint}/download`);
  } catch (e) {
    return e;
  }
};
