import { get, download } from "services/LogService";

export default function useLogs() {
  const handleLogs = async (page) => {
    return await get(page);
  };

  const handleDownload = async () => {
    return await download();
  };

  return { handleLogs, handleDownload };
}
