import styled from "styled-components";

export const TableBox = styled.div`
  display: flex;

  flex-direction: column;
`;

export const TableActions = styled.div`
  display: flex;
  padding: 25px 30px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

export const FormBox = styled.div`
  width: 95%;
  display: flex;
  margin: 0 auto;
  padding: 25px 0;
  flex-direction: column;
`;

export const FormTitle = styled.h1`
  width: 100%;
  display: flex;
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 15px;
  .MuiButton-root {
    width: auto;
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const PaginationBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
