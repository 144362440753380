import React from "react";
import { useUsers } from "hooks";
import { Topbar } from "components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Typography,
  TableBody,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Add, Edit, Delete } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { TableBox, TableActions, PaginationBox } from "assets/css/global";
import UserForm from "./components/UserForm";
import { SnackbarContext } from "contexts/SnackbarContext";

export default function Users() {
  const { handleUsers, handleUserDelete } = useUsers();
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [data, setData] = React.useState(null);
  const [paginate, setPaginate] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await handleUsers(page);
      if (status === 200) {
        setData(data.data);
        setPaginate({
          lastPage: data.lastPage,
          page: data.page,
          perPage: data.perPage,
          total: data.total,
        });
      }
    } catch (e) {}
  }, [page, handleUsers]);

  const handleDelete = async () => {
    try {
      const { status } = await handleUserDelete(user.id);
      if (status === 200) {
        handleData();
        openSnackbar("Registro excluído com sucesso", "success");
      }
    } catch (e) {
      openSnackbar(
        "Ops. Tivemos um problema, tente novamente mais tarde.",
        "error"
      );
    }
  };

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <>
      <Topbar />
      <TableBox>
        <TableActions>
          <Typography variant="h6">Usuários</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => {
              setUser({});
              setOpenModal(true);
            }}
          >
            Novo Registro
          </Button>
        </TableActions>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((user) => (
                <TableRow key={Math.random()}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    {user.is_admin ? "FULL ADMIN" : "USUÁRIO"}
                  </TableCell>
                  <TableCell>{`${user.name} ${user.surname}`}</TableCell>
                  <TableCell>{user.document || "Não informado"}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.cellphone}</TableCell>
                  <TableCell>{user.enabled ? "Ativo" : "Inativo"}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => {
                        setUser(user);
                        setOpenModal(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={() => {
                        setUser(user);
                        setOpenConfirm(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length <= 0 && (
              <TableRow>
                <TableCell colspan={7} style={{ textAlign: "center" }}>
                  Nenhum registro encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableBox>
      <PaginationBox>
        {paginate && (
          <Pagination
            count={paginate.lastPage}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        )}
      </PaginationBox>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Deseja realmente deletar o registro?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A ação não tem opção de retorno.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpenConfirm(false)}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              handleDelete(user);
              setOpenConfirm(false);
            }}
            color="primary"
            autoFocus
          >
            Sim, deletar.
          </Button>
        </DialogActions>
      </Dialog>
      <UserForm
        open={openModal}
        data={user}
        onClose={(refresh) => {
          if (refresh) {
            handleData();
          }
          setUser(null);
          setOpenModal(false);
        }}
      />
    </>
  );
}
